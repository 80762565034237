export function isArrEmpty(arr) {
    if (arr === undefined || arr === null || arr.length === 0) {
        return true;
    } else {
        return false;
    }
}

export function isStringEmpty(str) {
    console.log(str);
    if (str === undefined || str === null || str === '') {
        return true;
    } else {
        return false;
    }
}

export function isStringNotEmpty(str) {
    return !isStringEmpty(str);
}