<template>
  <div class="memo_record">
    <!--    <van-nav-bar title="备忘记录" />-->
    <van-calendar
        title="备忘记录"
        :show-subtitle="true"
        :poppable="false"
        :show-confirm="false"
        :style="{ height: calendar.calendarHeight }"
        :min-date="calendar.minDate" :max-date="calendar.maxDate"
        :formatter="calendar.formatter"
        class="memo_record-calendar"
        ref="myCalendar"
        :lazy-render="true"
        @month-show="calendar.monthShow"
        :default-date="calendar.selectedDate"
        @select="calendar.updateSelectDate"
    />
    <button @click="calendar.showPreviousMonth" v-if="calendar.showMonthBtn" class="memo_record-calendar-button">
      <svg t="1706175716505" class="arrow-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
           p-id="1952">
        <path
            d="M631.04 161.941333a42.666667 42.666667 0 0 1 63.061333 57.386667l-2.474666 2.730667-289.962667 292.245333 289.706667 287.402667a42.666667 42.666667 0 0 1 2.730666 57.6l-2.474666 2.752a42.666667 42.666667 0 0 1-57.6 2.709333l-2.752-2.474667-320-317.44a42.666667 42.666667 0 0 1-2.709334-57.6l2.474667-2.752 320-322.56z"
            fill="#dbdbdb" p-id="1953"></path>
      </svg>
    </button>
    <button @click="calendar.showPreviousWeek" v-if="calendar.showWeekBtn" class="memo_record-calendar-button">
      <svg t="1706175716505" class="arrow-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
           p-id="1952">
        <path
            d="M631.04 161.941333a42.666667 42.666667 0 0 1 63.061333 57.386667l-2.474666 2.730667-289.962667 292.245333 289.706667 287.402667a42.666667 42.666667 0 0 1 2.730666 57.6l-2.474666 2.752a42.666667 42.666667 0 0 1-57.6 2.709333l-2.752-2.474667-320-317.44a42.666667 42.666667 0 0 1-2.709334-57.6l2.474667-2.752 320-322.56z"
            fill="#dbdbdb" p-id="1953"></path>
      </svg>
    </button>
    <button @click="calendar.toggleHeight" class="memo_record-calendar-button">
      <!--      <svg t="1706174960791" class="arrow-icon" v-if="calendar.showUpIcon" viewBox="0 0 1024 1024" version="1.1"-->
      <!--           xmlns="http://www.w3.org/2000/svg" p-id="1640">-->
      <!--        <path-->
      <!--            d="M722.773333 381.44a64 64 0 0 1 90.453334 90.453333l-252.970667 253.013334a68.266667 68.266667 0 0 1-96.512 0l-253.013333-253.013334a64 64 0 0 1 90.538666-90.453333L512 592.128l210.773333-210.773333z"-->
      <!--            fill="#111111" p-id="1641" transform="rotate(180, 512, 512)"></path>-->
      <!--      </svg>-->
      <!--      <svg t="1706174960791" class="arrow-icon" v-else viewBox="0 0 1024 1024" version="1.1"-->
      <!--           xmlns="http://www.w3.org/2000/svg" p-id="1640">-->
      <!--        <path-->
      <!--            d="M722.773333 381.44a64 64 0 0 1 90.453334 90.453333l-252.970667 253.013334a68.266667 68.266667 0 0 1-96.512 0l-253.013333-253.013334a64 64 0 0 1 90.538666-90.453333L512 592.128l210.773333-210.773333z"-->
      <!--            fill="#111111" p-id="1641"></path>-->
      <!--      </svg>-->
      <svg t="1706175716505" class="arrow-icon" v-if="calendar.showUpIcon" viewBox="0 0 1024 1024" version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           p-id="1952">
        <path
            d="M631.04 161.941333a42.666667 42.666667 0 0 1 63.061333 57.386667l-2.474666 2.730667-289.962667 292.245333 289.706667 287.402667a42.666667 42.666667 0 0 1 2.730666 57.6l-2.474666 2.752a42.666667 42.666667 0 0 1-57.6 2.709333l-2.752-2.474667-320-317.44a42.666667 42.666667 0 0 1-2.709334-57.6l2.474667-2.752 320-322.56z"
            fill="#dbdbdb" p-id="1953" transform="rotate(90, 512, 512)"></path>
      </svg>
      <svg t="1706175716505" class="arrow-icon" v-else viewBox="0 0 1024 1024" version="1.1"
           xmlns="http://www.w3.org/2000/svg"
           p-id="1952">
        <path
            d="M631.04 161.941333a42.666667 42.666667 0 0 1 63.061333 57.386667l-2.474666 2.730667-289.962667 292.245333 289.706667 287.402667a42.666667 42.666667 0 0 1 2.730666 57.6l-2.474666 2.752a42.666667 42.666667 0 0 1-57.6 2.709333l-2.752-2.474667-320-317.44a42.666667 42.666667 0 0 1-2.709334-57.6l2.474667-2.752 320-322.56z"
            fill="#dbdbdb" p-id="1953" transform="rotate(270, 512, 512)"></path>
      </svg>
    </button>
    <button @click="calendar.showNextMonth" v-if="calendar.showMonthBtn" class="memo_record-calendar-button">
      <svg t="1706175716505" class="arrow-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
           p-id="1952">
        <path
            d="M631.04 161.941333a42.666667 42.666667 0 0 1 63.061333 57.386667l-2.474666 2.730667-289.962667 292.245333 289.706667 287.402667a42.666667 42.666667 0 0 1 2.730666 57.6l-2.474666 2.752a42.666667 42.666667 0 0 1-57.6 2.709333l-2.752-2.474667-320-317.44a42.666667 42.666667 0 0 1-2.709334-57.6l2.474667-2.752 320-322.56z"
            fill="#dbdbdb" p-id="1953" transform="rotate(180, 512, 512)"></path>
      </svg>
    </button>
    <button @click="calendar.showNextWeek" v-if="calendar.showWeekBtn" class="memo_record-calendar-button">
      <svg t="1706175716505" class="arrow-icon" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"
           p-id="1952">
        <path
            d="M631.04 161.941333a42.666667 42.666667 0 0 1 63.061333 57.386667l-2.474666 2.730667-289.962667 292.245333 289.706667 287.402667a42.666667 42.666667 0 0 1 2.730666 57.6l-2.474666 2.752a42.666667 42.666667 0 0 1-57.6 2.709333l-2.752-2.474667-320-317.44a42.666667 42.666667 0 0 1-2.709334-57.6l2.474667-2.752 320-322.56z"
            fill="#dbdbdb" p-id="1953" transform="rotate(180, 512, 512)"></path>
      </svg>
    </button>

<!--    <van-divider/>-->

    <van-cell-group title="未完成的备忘" v-if="dateMemo.unfinishedMemoList !== undefined">
      <van-swipe-cell v-for="unfinishedMemo in dateMemo.unfinishedMemoList">
        <van-cell :title="unfinishedMemo.content" :value="dateMemo.getRemindTime(unfinishedMemo)"
                  title-class="memo_record-cell-title"
                  :class="{
                    'auto_complete-cell': unfinishedMemo.autoCompleteFlag,
                    'unfinished-cell': dateMemo.isLastUnfinished(unfinishedMemo)  /* 判断是否为昨天或以前的日期 */
                  }"
        >
          <template #label>
            <span>再提醒间隔：{{ unfinishedMemo.remindInterval }}分钟</span><br/>
            <span>提醒次数：{{ unfinishedMemo.remindFreq }}</span>
          </template>
        </van-cell>
        <template #left>
          <van-button square text="查看" type="primary" class="swipe-button"
                      @click="dateMemo.viewMemo(unfinishedMemo.memoId)"/>
        </template>
        <template #right>
          <van-button square :text="dateMemo.completeBtnText" type="primary" class="swipe-button"
                      @click="dateMemo.setMemoFinished(unfinishedMemo,  true)"/>
        </template>
      </van-swipe-cell>
    </van-cell-group>
    <van-cell-group title="已完成的备忘" v-if="dateMemo.finishedMemoList !== undefined">
      <van-swipe-cell v-for="finishedMemo in dateMemo.finishedMemoList">
        <van-cell :title="finishedMemo.content" :value="dateMemo.getRemindTime(finishedMemo)"
                  title-class="memo_record-cell-title" class="finished-cell">
          <template #label>
            <span>再提醒间隔：{{ finishedMemo.remindInterval }}分钟</span><br/>
            <span>提醒次数：{{ finishedMemo.remindFreq }}</span>
          </template>
        </van-cell>
        <template #left>
          <van-button square text="查看" type="primary" class="swipe-button"
                      @click="dateMemo.viewMemo(finishedMemo.memoId)"/>
        </template>
        <template #right>
          <van-button square :text="dateMemo.remindBtnText" type="primary" class="swipe-button"
                      @click="dateMemo.setMemoFinished(finishedMemo, false)"/>
        </template>
      </van-swipe-cell>
    </van-cell-group>
  </div>
</template>


<script setup>
import {ref, computed, watchEffect, onMounted, reactive} from 'vue';
import {useStore} from 'vuex';
import {addDays, addMonths, addWeeks} from 'date-fns';
import dayjs from 'dayjs';
import {showNotify} from 'vant';
import {useRoute, useRouter} from 'vue-router';
import memoApi from '@/api/memo'

const router = useRouter();
const route = useRoute();

const store = useStore();
const myCalendar = ref(null);
// console.log("myCalendar", myCalendar);


function calculateMaxDate(tempDate) {
  let selectedDate = dayjs(tempDate).format('YYYY-MM-DD');
  const day = dayjs(selectedDate).date(); // 重新生成 dayjs 实例来获取日期
  let maxDate = addMonths(selectedDate, 1);
  maxDate = addDays(maxDate, -day);
  // console.log("maxDate", maxDate);
  return maxDate;
}

function calculateMinDate(tempDate) {
  let selectedDate = dayjs(tempDate).format('YYYY-MM-DD');
  const day = dayjs(selectedDate).date(); // 重新生成 dayjs 实例来获取日期
  let minDate = addDays(selectedDate, -day + 1);
  // console.log("minDate", minDate);
  return minDate;
}

const calendar = reactive({
  minDate: ref(),
  maxDate: ref(),
  showUpIcon: false,
  showWeekBtn: true,
  showMonthBtn: false,
  selectedDate: ref(),
  month: ref(),
  calendarHeight: '180px',
  monthMemo: {},
  monthShow: ({date, title}) => {
    const month = dayjs(date).format('YYYY-MM');
    calendar.month = month;
    dateMemo.getMonthMemo(month);
  },
  toggleHeight: () => {
    if (calendar.calendarHeight === '180px') {
      calendar.calendarHeight = '500px';
      calendar.showUpIcon = true;
      calendar.showMonthBtn = true;
      calendar.showWeekBtn = false;
    } else {
      calendar.calendarHeight = '180px';
      calendar.showUpIcon = false;
      calendar.showMonthBtn = false;
      calendar.showWeekBtn = true;
    }

    setTimeout(() => {
      myCalendar.value.scrollToDate(myCalendar.value.getSelectedDate());

    }, 300)

  },
  formatter: (day) => {
    const date = dayjs(day.date.toDateString()).format('YYYY-MM-DD');
    // console.log(dateStr)
    // console.log("触发formatter", date , calendar.monthMemo[date]);

    const completed = calendar.monthMemo[date];
    if (completed !== undefined) {
      day.bottomInfo = '●';
      if (completed === true) {
        day.className = "memo-tip-finished";
      } else {
        day.className = "memo-tip-unfinished";
      }
    }
    return day;
  },
  showPreviousWeek: () => {
    const selectDate = addWeeks(calendar.selectedDate, -1)
    calendar.minDate = calculateMinDate(selectDate);
    calendar.maxDate = calculateMaxDate(selectDate);
    calendar.updateSelectDate(selectDate)
  },
  showPreviousMonth: () => {
    const selectDate = addMonths(calendar.selectedDate, -1)
    calendar.minDate = calculateMinDate(selectDate);
    calendar.maxDate = calculateMaxDate(selectDate);
    calendar.updateSelectDate(selectDate)
  },
  showNextMonth: () => {
    const selectDate = addMonths(calendar.selectedDate, 1)
    calendar.minDate = calculateMinDate(selectDate);
    calendar.maxDate = calculateMaxDate(selectDate);
    calendar.updateSelectDate(selectDate);
  },
  showNextWeek: () => {
    const selectDate = addWeeks(calendar.selectedDate, 1)
    calendar.minDate = calculateMinDate(selectDate);
    calendar.maxDate = calculateMaxDate(selectDate);
    calendar.month = dayjs(selectDate).format('YYYY-MM');
    calendar.selectedDate = selectDate;
    calendar.updateSelectDate(selectDate)
    dateMemo.currentDate = dayjs(calendar.selectedDate).format('YYYY-MM-DD');
  },
  updateSelectDate: (date) => {
    dateMemo.currentDate = dayjs(date).format('YYYY-MM-DD');
    calendar.selectedDate = date;
    calendar.month = dayjs(date).format('YYYY-MM');
  },
  init: () => {
    const routeSelectedDate = route.query.selectedDate;
    console.log("routeSelectDate:", routeSelectedDate)
    if (routeSelectedDate !== undefined) {
      console.log("入参有selectedDate", routeSelectedDate);
      calendar.selectedDate = dayjs(routeSelectedDate).toDate();
    } else {
      console.log("入参没有selectedDate")
      calendar.selectedDate = new Date();
    }

    // const year = calendar.selectedDate.getFullYear();
    // const month = calendar.selectedDate.getMonth();
    calendar.minDate = calculateMinDate(calendar.selectedDate);
    calendar.maxDate = calculateMaxDate(calendar.selectedDate);
    calendar.month = dayjs(calendar.selectedDate).format('YYYY-MM');
  },
});
calendar.init();


const currentCode = computed(() => store.getters.getLoginCode);

const dateMemo = reactive({
  unfinishedMemoList: [],
  finishedMemoList: [],
  currentDate: '',
  completeBtnText: '已完成',
  remindBtnText: '未完成',
  getRemindTime: (memo) => {
    const lastRemindTimeStr = memo.lastRemindTimeStr;
    const latestRemindTimeStr = memo.latestRemindTimeStr;

    if (latestRemindTimeStr !== null && latestRemindTimeStr !== undefined) {
      const dateStr = dayjs(latestRemindTimeStr).format('YYYY-MM-DD');
      const timeStr = dayjs(latestRemindTimeStr).format('HH:mm:ss');
      if (dateStr === dateMemo.currentDate) {
        return timeStr;
      }
    }
    if (lastRemindTimeStr !== null && lastRemindTimeStr !== undefined) {
      const timeStr = dayjs(lastRemindTimeStr).format('HH:mm:ss');
      return timeStr;
    }
    return '暂无提醒';
  },
  getDateMemo: () => {
    memoApi.getMemoRecordDetail(currentCode.value, dateMemo.currentDate)
        .then(function (response) {
          // 处理成功情况
          // console.log("response", response);
          const result = response.data.result;
          if (result !== undefined) {
            dateMemo.finishedMemoList = result.finishedMemoList;
            dateMemo.unfinishedMemoList = result.unfinishedMemoList;

            const afterToday = result.afterToday;
            // console.log("afterToday", afterToday);
            if (afterToday === true) {
              dateMemo.completeBtnText = '关闭提醒';
              dateMemo.remindBtnText = '开启提醒';
            } else {
              dateMemo.completeBtnText = '已完成';
              dateMemo.remindBtnText = '未完成';
            }
          }
        })
        .catch(function (error) {
          // 处理错误情况
          console.log(error);
        })
        .finally(function () {
          // 总是会执行
        })
  },
  getMonthMemo: (month) => {
    // console.log("getMonthMemo")
    memoApi.getMonthMemoRecord(currentCode.value, month)
        .then(function (response) {
          // 处理成功情况
          // console.log("response", response);
          const result = response.data.result;
          if (result !== undefined) {
            const memoRecordDTOList = result.memoRecordDTOList;
            // console.log("memoRecordDTOList", memoRecordDTOList)
            const monthMemo = memoRecordDTOList.reduce((acc, item) => {
              acc[item.remindDate] = item.completed;
              return acc;
            }, {});
            calendar.monthMemo = monthMemo;
          }
        })
        .catch(function (error) {
          // 处理错误情况
          console.log(error);
        })
        .finally(function () {
          // 总是会执行
        })
  },
  setMemoFinished: (memo, completed) => {
    // console.log("setMemoFinished", memo);

    const data = {
      memoId: memo.memoId,
      date: dateMemo.currentDate,
      completed: completed
    };
    // console.log(data)

    memoApi.setMemoCompleted(currentCode.value, data)
        .then(function (response) {
          // console.log(response);
          if (response.data.code === 200) {
            let result = response.data.result;
            if (result !== undefined) {
              // console.log("result", result);
              dateMemo.finishedMemoList = result.finishedMemoList;
              dateMemo.unfinishedMemoList = result.unfinishedMemoList;
              if (dateMemo.unfinishedMemoList.length <= 1) {
                console.log("unfinishedMemoList为空")
                // 刷新一下日历上的红点
                dateMemo.getMonthMemo(calendar.month);
              }
            }
          } else {
            let msg = response.data.msg;
            showNotify({type: 'warning', message: msg});
          }
        })
        .catch(function (error) {
          console.log(error);
        });

  },
  viewMemo: (memoId) => {
    // console.log("触发查看备忘, memoId:", memoId);
    router.push({path: '/memo', query: {code: currentCode.value, memoId: memoId}});
  },
  isLastUnfinished: (memo) => {
    // 获取今天和昨天的日期对象
    const now = new Date();
    const lastRemindTimeStr = memo.lastRemindTime;
    if (lastRemindTimeStr !== null && lastRemindTimeStr !== undefined) {
      const lastRemindTime = dayjs(lastRemindTimeStr);
      if (lastRemindTime !== null && lastRemindTime !== undefined) {
        if (lastRemindTime <= now) {
          return true;
        }
      }
      return false;
    }

  },
  init: () => {
    dateMemo.currentDate = dayjs(calendar.selectedDate).format('YYYY-MM-DD');
  }
});
dateMemo.init();


// 监听 currentCode 的变化
watchEffect(() => {
  // console.log(dateMemo.finishedMemoList, dateMemo.unfinishedMemoList);
  dateMemo.getMonthMemo(calendar.month);
});

watchEffect(() => {
  dateMemo.getDateMemo();
});


</script>

<style lang="scss" scoped>
.memo_record {
  transition: all 0.3s ease;
  overflow-y: scroll;
}

.memo_record-calendar-button {
  background: none;
  border: none;
  margin: 1rem 1.5rem;
}

.arrow-icon {
  width: 3rem;
  height: 1.5rem;
}

.swipe-button {
  height: 100%;
  width: 100%;
}

.unfinished-cell {
  background-color: rgb(255, 232, 230); /* 自定义背景颜色 */
  color: rgb(224, 69, 68);
}

.finished-cell {
  //background-color: #dfffe0; /* 自定义背景颜色 */
  //background-color: #dfffe0; /* 自定义背景颜色 */
  color: #006400;
  background-color: rgb(239, 255, 241); /* 自定义背景颜色 */
  //color: rgb(57, 181, 74);
}
.auto_complete-cell {
  //background-color: rgb(214, 232, 254);
  //background-color: #e0f7ff;
  //color: #004085;
  background-color: rgb(232, 240, 254);
  color: #004085;
}
</style>

<style lang="scss">

.memo_record {
  .van-swipe-cell__right {
    width: 5.5em;
  }
  .van-swipe-cell__left {
    width: 5em;
  }
}

.memo_record-calendar {
  transition: height 0.3s ease; // 添加过渡动画
  overflow-y: scroll;
  position: sticky;
  top: 0;

  .van-calendar__selected-day {
    border-radius: 50%;
    height: 3rem;
    width: 3rem;
  }

  //
  //.van-calendar__day--selected {
  //  position: sticky;
  //  top: 0;
  //}

}

.van-cell {
  .memo_record-cell-title {
    text-align: left;
  }
}

.van-calendar__day.memo-tip-finished {
  .van-calendar__bottom-info {
    color: #267eea;
    font-size: 0.5em;
  }
}

.van-calendar__day.memo-tip-unfinished {
  .van-calendar__bottom-info {
    color: #ef4a43;
    font-size: 0.5em;
  }
}


</style>