<template>
  <div>
    <Login />
    <UserInfo />
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'
import UserInfo from '@/components/UserInfo.vue'
import {ref} from "vue";

export default {
  name: 'UserInfoView',
  components: {
    Login,
    UserInfo
  },
  setup() {
    document.title = '个人中心';
    const parentActive = ref(0);
    return { parentActive };
  }
}
</script>