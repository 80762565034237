<template>
  <div>
    <van-nav-bar title="个人中心"/>
    <van-field v-model="userInfo.nickname" label="昵称" placeholder="未设置" is-link readonly
               @click="userInfo.clickNickname"/>
    <van-field v-model="userInfo.email" label="邮箱" placeholder="未绑定" is-link readonly
               @click="userInfo.clickEmail"/>


    <van-dialog v-model:show="nicknameDialog.show" title="修改昵称" show-cancel-button confirmButtonText="保存"
                cancelButtonText="取消"
                @confirm="nicknameDialog.saveInfo" :confirmButtonDisabled="nicknameDialog.saveBtnDisabled">
      <van-field v-model="nicknameDialog.nickname" label="昵称" placeholder="请输入昵称"
                 label-width="3em"/>
    </van-dialog>

    <van-dialog v-model:show="emailDialog.show" title="绑定邮箱" show-cancel-button confirmButtonText="保存"
                cancelButtonText="取消"
                @confirm="emailDialog.saveInfo" :confirmButtonDisabled="emailDialog.saveBtnDisabled"
                class="userinfo-email_dialog">
      <van-field v-model="emailDialog.email" label="邮箱"
                 center
                 label-width="3em"
                 placeholder="请输入邮箱">
        <template #button>
          <!--          {{ emailDialog.emailCodeBtnText}}-->
          <van-button size="small" type="primary" :disabled="emailDialog.getEmailCodeDisabled"
                      @click="emailDialog.getEmailCode">
            <span v-if="emailDialog.emailCodeBtnShow">获取验证码</span>
            <van-count-down :time="emailDialog.countDownTime" ref="countDown" v-if="!emailDialog.emailCodeBtnShow"
                            @finish="emailDialog.countDownFinish">
              <template #default="timeData">
                <span color="#000000">已发送 {{ timeData.seconds }}</span>
              </template>
            </van-count-down>
          </van-button>
        </template>
      </van-field>
      <van-field v-model="emailDialog.emailCode" label="验证码" placeholder="请输入验证码"
                 label-width="3em"/>
    </van-dialog>
  </div>
</template>

<script setup>
import {ref, computed, watchEffect, onMounted, reactive} from 'vue';
import {default as axios} from "axios";
import {useStore} from 'vuex';
import {showNotify} from "vant";

const store = useStore();
const currentCode = computed(() => store.getters.getLoginCode);

const countDown = ref(null);


const userInfo = reactive({
  nickname: ref(),
  email: ref(),
  clickNickname: () => {
    console.log("clickNickname")
    nicknameDialog.show = true;
  },
  clickEmail: () => {
    console.log("clickEmail")
    emailDialog.show = true;
  }
});
const nicknameDialog = reactive({
  show: false,
  nickname: ref(''),
  saveInfo: () => {
    console.log("nicknameDialog saveInfo:", nicknameDialog.nickname)
    const data = {
      nickname: nicknameDialog.nickname
    };
    axios.post('http://warsun.top/aio_svc/v1/modify_user_info?code=' + currentCode.value, data)
        .then(function (response) {
          console.log(response);
          if (response.data.code === 200) {
            showNotify({type: 'success', message: "修改成功"});
            userInfo.nickname = nicknameDialog.nickname;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
  },
  saveBtnDisabled: computed(() => {
    if (nicknameDialog.nickname === '') {
      return true;
    } else {
      return false;
    }
  })

});
const emailDialog = reactive({
  show: false,
  email: ref(''),
  emailCode: ref(''),
  countDownTime: ref(60 * 1000),
  emailCodeBtnShow: true,
  saveInfo: async () => {
    console.log("emailDialog saveInfo")
    if (emailDialog.email === userInfo.email) {
      await new Promise(resolve => {
        setTimeout(() => {
          emailDialog.show = true;
          showNotify({type: 'warning', message: "绑定邮箱与原邮箱相同"});
          resolve();
        }, 200); // 延迟1秒
      });
    } else {
      const data = {
        email: emailDialog.email,
        emailCode: emailDialog.emailCode
      };
      axios.post('http://warsun.top/aio_svc/v1/bind_email?code=' + currentCode.value, data)
          .then(function (response) {
            console.log(response);
            if (response.data.code === 200) {
              userInfo.email = emailDialog.email;
              showNotify({type: 'success', message: "绑定成功"});
              emailDialog.emailCode = '';
            } else {
              let msg = response.data.msg;
              emailDialog.show = true;
              showNotify({type: 'warning', message: msg});
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }
  },
  countDownFinish: () => {
    console.log("countDownFinish")
    emailDialog.emailCodeBtnShow = true;
  },
  saveBtnDisabled: computed(() => {
    if (emailDialog.emailCode === '' || emailDialog.email === '') {
      return true;
    } else {
      return false;
    }
  }),
  getEmailCodeDisabled: computed(() => {
    if (emailDialog.email === '' || !emailDialog.emailCodeBtnShow) {
      return true;
    } else {
      return false;
    }
  }),
  getEmailCode: () => {
    console.log("getEmailCode")

    // console.log("countDown:", countDown);
    // console.log("countDown:", countDown.value);
    // countDown.value.reset();
    countDownTime: ref(60 * 1000),
        emailDialog.emailCodeBtnShow = false;

    const data = {
      email: emailDialog.email
    };

    axios.post('http://warsun.top/aio_svc/v1/send_email_code?code=' + currentCode.value, data)
        .then(function (response) {
          console.log(response);
          if (response.data.code === 200) {
          } else {
            showNotify({ type: 'warning', message: "获取验证码失败，请检查邮箱是否正确" });
          }
        })
        .catch(function (error) {
          console.log(error);
        });

  }
});


// 展示上一次的数据
const getUserInfo = () => {
  console.log("currentCode.value:", currentCode.value)
  if (currentCode.value === null) {
    return;
  }
  axios.get('http://warsun.top/aio_svc/v1/get_user_info?code=' + currentCode.value)
      .then(function (response) {
        const info = response.data.result;
        console.log(info);
        const email = info.email;
        const nickname = info.nickname;
        if (email !== undefined) {
          userInfo.email = email;
          emailDialog.email = email;
        }
        if (nickname !== undefined) {
          userInfo.nickname = nickname;
          nicknameDialog.nickname = nickname;
        }
      })
      .catch(function (error) {
        // 处理错误情况
        console.log(error);
      })
      .finally(function () {
        // 总是会执行
      })
}

// 监听 currentCode 的变化
watchEffect(() => {
  getUserInfo();
});


</script>

<style lang="scss">
.userinfo-email_dialog {
  .van-button--primary {
    background: none;
    border: none;
  }

  .van-button__text {
    color: #1989fa;
  }

  .van-count-down {
    color: #1989fa;
  }

  .van-button--disabled {
    opacity: 0.6;
  }
}
</style>

<style scoped lang="scss">
</style>