<template>
  <div>
    <Login />
    <Notice />
    <MemoTab :active="parentActive"/>
    <Memo />
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'
import Memo from '@/components/Memo.vue'
import MemoTab from '@/components/tab/MemoTab'
import Notice from "@/components/Notice";
import {ref} from "vue";

export default {
  name: 'MemoView',
  components: {
    Login,
    Memo,
    MemoTab,
    Notice
  },
  setup() {
    document.title = '备忘';
    const parentActive = ref(0);
    return { parentActive };
  }
}
</script>