import { createRouter, createWebHistory } from 'vue-router'
import GoldView from '../views/GoldView.vue'
import GoldCalcView from '../views/GoldCalcView.vue'
import MemoView from "@/views/MemoView.vue";
import MemoRecordView from "@/views/MemoRecordView";
import UserInfoView from "@/views/UserInfoView";
import HomepageView from "@/views/HomepageView.vue";

const routes = [
  // {
  //   path: '/',
  //   name: 'home',
  //   component: HomepageView
  // },
  // {
  //   path: '/about',
  //   name: 'about',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/AboutView.vue')
  // },
  {
    path: '/gold',
    name: 'gold_view',
    component: GoldView,
    meta: {
      title: '金价'
    }
  },
  {
    path: '/gold_calc',
    name: 'gold_calc_view',
    component: GoldCalcView,
    meta: {
      title: '金价计算器'
    }
  },
  {
    path: '/memo',
    name: 'memo_view',
    component: MemoView,
    meta: {
      title: '备忘'
    }
  },
  {
    path: '/memo_record',
    name: 'memo_record_view',
    component: MemoRecordView,
    meta: {
      title: '备忘记录'
    }
  },
  {
    path: '/user_info',
    name: 'user_info_view',
    component: UserInfoView
  },

  // 开发中的页面
  // {
  //   path: '/memo_dev',
  //   name: 'memo_view_dev',
  //   component: MemoViewDev,
  //   meta: {
  //     title: '备忘'
  //   }
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
