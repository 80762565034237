import axios from "axios";
import {baseUrl} from './common';


const userApi = {
    loginByCode(data) {
        console.log("调用登录接口");
        return axios.post(baseUrl + '/aio_svc/v1/login_by_code', data);
    },
    getUserInfo(code) {
        return axios.get(`${baseUrl}/aio_svc/v1/get_user_info?code=${code}`)
    },

}


export default userApi;