<template>
  <van-tabs v-model:active="active" sticky>
    <van-tab title="仓位建议" :to="{ path: '/gold', query: { code } }"></van-tab>
    <van-tab title="金价计算器" :to="{ path: '/gold_calc', query: { code } }"></van-tab>
  </van-tabs>
</template>

<script setup>
import { ref, defineProps, watch, onMounted } from 'vue';
import {useRoute, useRouter} from "vue-router";
const props = defineProps(['active']);
const active = ref(props.active);


const route = useRoute();
const router = useRouter();
const code = ref(route.query.code);

const routeChangeHandler = () => {
  // 监听路由变化，更新 code
  code.value = route.query.code;
};

onMounted(() => {
  // 在组件挂载后添加路由变化监听
  watch(() => route.query.code, routeChangeHandler);
});

</script>

<style scoped lang="scss">
</style>