<template>
  <div>
    <van-nav-bar title="仓位建议"/>
    <van-cell-group inset>
      <van-field v-model="targetPrice" label="目标金价" placeholder="请输入目标金价"/>
      <van-field v-model="amount" label="总成本" placeholder="请输入总成本"/>
      <van-field label="仓位建议" v-model="positionDesc" readonly/>
      <van-field label="相对涨跌幅" v-model="priceChange" readonly/>
      <van-field label="操作金额" v-model="handleAmount" readonly/>
    </van-cell-group>
    <van-button type="primary" @click="onClickSearch">查询</van-button>
  </div>
</template>

<script setup>
import {ref} from 'vue';
import {default as axios} from "axios";

const targetPrice = ref('');
const positionDesc = ref('');
const priceChange = ref('');
const amount = ref('');
const handleAmount = ref('');

const onClickSearch = () => {
  console.log("确认", targetPrice)

  axios.get('http://warsun.top/aio_svc/v1/get_gold_price_refer?target_price=' + targetPrice.value +
      "&amount=" + (amount.value !== undefined ? amount.value : ''))
      .then(function (response) {
        // 处理成功情况
        const result = response.data.result;
        positionDesc.value = result.positionDesc;
        if (result.priceChange !== undefined) {
          priceChange.value = result.priceChange + "%"
        }
        handleAmount.value = result.handleAmount;
        console.log(response);
      })
      .catch(function (error) {
        // 处理错误情况
        console.log(error);
      })
      .finally(function () {
        // 总是会执行
      })
};

</script>

<style scoped lang="scss">
.custom-cell-group {
  /* 在这里添加你想要共享的样式 */
}
</style>