<template>
  <div>
    <Login />
    <GoldTab :active="parentActive"/>
    <GoldPriceRef />
  </div>
</template>

<script>
// @ is an alias to /src
import GoldPriceRef from '@/components/GoldPriceRef.vue'
import GoldTab from '@/components/tab/GoldTab.vue'
import Login from '@/components/Login.vue'

import {ref} from "vue";

export default {
  name: 'GoldView',
  components: {
    GoldPriceRef,
    GoldTab,
    Login
  },
  setup() {
    document.title = '金价';
    const parentActive = ref(0);
    return { parentActive };
  }
}
</script>