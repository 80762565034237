<template>
  <van-dialog v-model:show="showLogin" title="登录" @confirm="submitCode">
    <van-field v-model="code" label="code" placeholder="请输入登录密钥" />
  </van-dialog>
</template>

<script setup>
import {inject, ref} from 'vue';
import {useRoute, useRouter} from 'vue-router';
import {showDialog} from 'vant';
import { useStore } from 'vuex';
import userApi from "@/api/user";

// 在组件挂载后获取路由参数
const route = useRoute();
const router = useRouter();

const store = useStore();

const code = ref(route.query.code);

// 登录
const showLogin = ref(false)

const submitCode = () => {
  // console.log("submitCode")
  login(code.value)
}

const login = (codeValue) => {
  if (codeValue == undefined) {
    showLogin.value = true
    code.value = ''
  }
  console.log("登录")
  const data = {
    code: codeValue
  };
  userApi.loginByCode(data)
      .then(function (response) {
        const result = response.data.result
        if (result !== undefined) {
          const status = result.status;
          if (status === 1) {
            const groupId = result.group_id;
            // let userId = result.user_id;
            // console.log("登录成功", result);
            // 修改路由中的code
            const currentRoute = router.currentRoute.value;
            const newQuery = { ...currentRoute.query, code: codeValue };
            router.replace({ path: currentRoute.path, query: newQuery });
            // 更新vuex数据
            store.dispatch('updateLoginCode', codeValue);
            store.dispatch('updateGroupId', groupId);
            // console.log("loginCode:", codeValue)
          } else {
            console.log("登录失败");
            showDialog({  message: "登录失败，密钥无效",}).then(() => {
              showLogin.value = true
              code.value = ''
            });
          }
        }
      })
      .catch(function (error) {
        console.log(error);
      });
}

login(code.value)

</script>

<style scoped lang="scss">
</style>
