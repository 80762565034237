console.log("main")
import {createApp} from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'


import {
    Button,
    Form,
    Field,
    CellGroup,
    Cell,
    Picker,
    DatePicker,
    TimePicker,
    Popup,
    Stepper,
    SubmitBar,
    Dialog,
    Calendar,
    Col,
    Row,
    Divider,
    Sticky,
    NavBar,
    Tab,
    Tabs,
    SwipeCell,
    Notify,
    CountDown,
    NoticeBar,
    Switch
} from 'vant';

import 'vant/lib/index.css';

const app = createApp(App);
app.use(store)
    .use(router)
    .use(Form)
    .use(Button)
    .use(Field)
    .use(CellGroup)
    .use(Picker)
    .use(DatePicker)
    .use(TimePicker)
    .use(Stepper)
    .use(SubmitBar)
    .use(Popup)
    .use(Dialog)
    .use(Calendar)
    .use(Cell)
    .use(Col)
    .use(Row)
    .use(Divider)
    .use(Sticky)
    .use(NavBar)
    .use(Tab)
    .use(Tabs)
    .use(SwipeCell)
    .use(Notify)
    .use(CountDown)
    .use(NoticeBar)
    .use(Switch)

app.mount('#app');