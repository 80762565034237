import dayjs from 'dayjs';
import {isStringNotEmpty, isStringEmpty} from '@/service/utils'

export function convertToDateArr(dateArrStr) {
    const dateArr = [];
    if (isStringEmpty(dateArrStr)) {
        return dateArr;
    }
    let dateStrArr = dateArrStr.split("|");
    for (let i = 0; i < dateStrArr.length; i++) {
        const dateStr = dateStrArr[i];
        let date = convertToDate(dateStr);
        dateArr.push(date);
    }
    return dateArr;
}

export function convertToDate(dateStr) {
    if (isStringEmpty(dateStr)) return null;
    const date = dayjs(dateStr).toDate();
    console.log("date", date);
    return date;
}
