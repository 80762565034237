<template>
  <div>
    <Login />
    <GoldTab :active="parentActive"/>
    <GoldCalc />
  </div>
</template>

<script>
// @ is an alias to /src
import GoldCalc from '@/components/GoldCalc.vue'
import GoldTab from '@/components/tab/GoldTab.vue'
import Login from '@/components/Login.vue'

import { ref } from 'vue';

export default {
  name: 'GoldCalcView',
  components: {
    GoldCalc,
    GoldTab,
    Login
  },
  setup() {
    document.title = '金价计算器';
    const parentActive = ref(1);
    return { parentActive };
  }
}
</script>