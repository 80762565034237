<template>
  <div>
    <Login />
    <MemoTab :active="parentActive"/>
    <MemoRecord />
  </div>
</template>

<script>
// @ is an alias to /src
import Login from '@/components/Login.vue'
import MemoRecord from '@/components/MemoRecord.vue'
import MemoTab from '@/components/tab/MemoTab'
import {ref} from "vue";

export default {
  name: 'MemoRecordView',
  components: {
    Login,
    MemoRecord,
    MemoTab
  },
  setup() {
    document.title = '备忘记录';
    const parentActive = ref(1);
    return { parentActive };
  }
}
</script>