<template>
  <div>
    <van-nav-bar title="金价计算器" />
    <div v-for="goldItem in goldList" :key="goldItem.id">
      <van-cell-group inset class="flex-container">
        <!-- 允许输入数字，调起带符号的纯数字键盘 -->
        <van-field v-model="goldItem.goldPrice" type="number" label="金价"/>
        <van-field v-model="goldItem.goldGrams" type="number" label="持仓克重" :disabled="goldItem.buyAmount !== ''"/>
        <van-field v-model="goldItem.buyAmount" type="number" label="买入金额" :disabled="goldItem.goldGrams !== ''"/>
      </van-cell-group>
      <van-divider/>
    </div>


    <van-row justify="center" van-row :gutter="[0,10]">
      <van-col span="4">
        <van-button icon="minus" @click="removeGoldItem"/>
      </van-col>
      <van-col span="4">
        <van-button icon="plus" @click="addGoldItem"/>
      </van-col>
      <van-col span="24">
        <van-button type="primary" @click="onClickCalc" :disabled="!isCalculationEnabled">计算</van-button>
      </van-col>
    </van-row>
    <van-divider/>

    <van-cell-group inset>
      <van-field label="成本均价" v-model="goldResult.averGoldPrice" readonly/>
      <van-field label="持仓克重" v-model="goldResult.goldGrams" readonly/>
      <van-field label="持仓金额" v-model="goldResult.buyAmount" readonly/>
      <van-field label="盈利价位" v-model="goldResult.profitPoint" readonly/>
      <van-field label="手续费" v-model="goldResult.txFee" readonly/>
    </van-cell-group>
    <van-divider style="margin-bottom: 50%;"/>
  </div>
</template>

<script setup>
import {ref, computed, watchEffect, onMounted } from 'vue';
import {default as axios} from "axios";
import { useStore } from 'vuex';

const store = useStore();

let id = 0
const goldList = ref([
  {id: id++, goldPrice: '', goldGrams: '', buyAmount: ''},
])

const goldResult = ref({
  averGoldPrice: '',
  goldGrams: '',
  buyAmount: '',
  profitPoint: '',
  txFee: ''
})

const addGoldItem = () => {
  goldList.value.push({id: id++, goldPrice: '', goldGrams: '', buyAmount: ''})
}

const removeGoldItem = () => {
  if (goldList.value.length > 1) {
    goldList.value.pop(); // 移除最后一个项目
  }
};

const isCalculationEnabled = computed(() => {
  let flag = true
  for (let goldItem of goldList.value) {
    console.log(goldItem)
    flag &&= goldItem.goldPrice !== '' && (goldItem.goldGrams !== '' || goldItem.buyAmount !== '');
  }
  return flag;
});

const onClickCalc = () => {
  let goldPriceList = [];
  for (let goldItem of goldList.value) {
    goldPriceList.push(        {
      goldPrice: goldItem.goldPrice,
      goldGrams: goldItem.goldGrams,
      buyAmount: goldItem.buyAmount
    });
  }
  console.log(goldPriceList)

  const data = {
    goldPriceList: goldPriceList,
    userId: null
  };
  console.log(data, "data")
  const currentCode = store.getters['getLoginCode'];
  console.log("currentCode:", currentCode)

  axios.post('http://warsun.top/aio_svc/v1/calc_user_gold_price?code=' + currentCode, data)
      .then(function (response) {
        console.log(response);
        if (response.data.code == 200) {
          let result = response.data.result;
          goldResult.value.averGoldPrice = result.averGoldPrice
          goldResult.value.buyAmount = result.buyAmount
          goldResult.value.goldGrams = result.goldGrams
          goldResult.value.profitPoint = result.profitPoint
          goldResult.value.txFee = result.txFee
        }
      })
      .catch(function (error) {
        console.log(error);
      });
};

const currentCode = computed(() => store.getters.getLoginCode);

// 展示上一次的数据
const showLastData = () => {
  console.log("currentCode:", currentCode.value)
  axios.get('http://warsun.top/aio_svc/v1/get_user_gold_price?code=' + currentCode.value)
      .then(function (response) {
        // 处理成功情况
        console.log('http://warsun.top/aio_svc/v1/get_user_gold_price?code='+ currentCode.value, response);
        let userGoldPriceList = response.data.result.userGoldPriceList;
        console.log('userGoldPriceList', userGoldPriceList)
        if (userGoldPriceList.length > 0) {
          goldList.value = [];
          id = 0;
          for (let userGoldPrice of userGoldPriceList) {
            console.log("userGoldPrice", userGoldPrice)
            goldList.value.push({
              id: id++,
              goldPrice: userGoldPrice.goldPrice ? userGoldPrice.goldPrice : '',
              goldGrams: userGoldPrice.goldGrams ? userGoldPrice.goldGrams : '',
              buyAmount: userGoldPrice.buyAmount ? userGoldPrice.buyAmount : ''
            });
          }
        }

      })
      .catch(function (error) {
        // 处理错误情况
        console.log(error);
      })
      .finally(function () {
        // 总是会执行
      })
}

// 监听 currentCode 的变化
watchEffect(() => {
  console.log("currentCode changed:", currentCode.value);
  // 在这里执行你想要的逻辑，比如调用 showLastData
  showLastData();
});



</script>

<style scoped lang="scss">
</style>