import axios from "axios";
import {baseUrl} from './common';

// 定义 memoApi 对象
const memoApi = {
    getMemoInfo(memoId) {
        return axios.get(`${baseUrl}/aio_svc/v1/get_memo_info?memoId=${memoId}`);
    },
    removeMemo(code, memoId) {
        return axios.post(`${baseUrl}/aio_svc/v1/remove_memo?code=${code}&memoId=${memoId}`);
    },
    addMemo(code, data) {
        return axios.post(`${baseUrl}/aio_svc/v1/add_memo?code=${code}`, data)
    },
    getMonthMemoRecord(code, month) {
        return axios.get(`${baseUrl}/aio_svc/v1/get_month_memo_record?code=${code}&month=${month}`);
    },
    getMemoRecordDetail(code, date) {
        return axios.get(`${baseUrl}/aio_svc/v1/get_date_memo_record_detail?code=${code}&date=${date}`)
    },
    setMemoCompleted(code, data) {
        return axios.post(`${baseUrl}/aio_svc/v1/set_memo_completed?code=${code}`, data)
    },

};

// 暴露 memoApi 对象
export default memoApi;
