import { createStore } from 'vuex'

export default createStore({
  state: {
    loginCode: null,
    groupId: null,
  },
  getters: {
    getLoginCode: (state) => state.loginCode,
    getGroupId: (state) => state.groupId,

  },
  mutations: {
    setLoginCode(state, newCode) {
      state.loginCode = newCode;
    },
    setGroupId(state, groupId) {
      state.groupId = groupId;
    },
  },
  actions: {
    updateLoginCode({ commit }, newCode) {
      commit('setLoginCode', newCode);
    },
    updateGroupId({ commit }, groupId) {
      commit('setGroupId', groupId);
    },
  },
  modules: {
  }
})
