<template>
  <van-notice-bar mode="closeable" scrollable
                  text="更新公告（2024.12.06）：新增功能（备忘设置自动完成）、修复已知bug"
  />
</template>

<script>
export default {
  name: "Notice"
}
</script>

<style scoped>

</style>