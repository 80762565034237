<template>
  <div class="memo">
    <van-nav-bar title="备忘"/>
    <van-form @submit="onSubmit">
      <!-- 时间类型 -->
      <van-field v-model="timeType.timeTypeText" :is-link="memo.isLink" readonly label="时间类型"
                 placeholder="选择时间类型"
                 @click="timeType.timeTypeShowPicker = memo.permitClick" name="timeType"/>
      <van-popup v-model:show="timeType.timeTypeShowPicker" round position="bottom">
        <van-picker :columns="timeType.timeTypeColumns" @cancel="timeType.timeTypeShowPicker = false"
                    @confirm="timeType.onConfirmTimeType" v-model="timeType.selectedTimeType"/>
      </van-popup>

      <!-- 备忘类型 -->
      <van-field v-model="memoType.memoTypeText" :is-link="memo.isLink" readonly label="备忘类型"
                 @click="memoType.memoTypeShowPicker = memo.permitClick" name="memoType" v-if="groupId != null"/>
      <van-popup v-model:show="memoType.memoTypeShowPicker" round position="bottom">
        <van-picker :columns="memoType.memoTypeColumns" @cancel="memoType.memoTypeShowPicker = false"
                    @confirm="memoType.onConfirmMemoType" v-model="memoType.selectedMemoType"/>
      </van-popup>


      <div v-for="(calendarResult, index) in calendar.calendarResultList" :key="calendarResult.id"
           class="van-hairline--bottom">
        <van-field
            v-model="calendarResult.dateStr"
            :is-link="index === 0 ? memo.isLink : false" readonly
            name="calendar"
            :label="index === 0 ? '选择日期' : ' '"
            placeholder="可选择多个提醒日期"
            @click="calendar.showCalendar = memo.permitClick"
            v-if="calendar.showCalendarSelect"
        />
      </div>

      <van-calendar v-model:show="calendar.showCalendar" :default-date="calendar.selectDateStrList"
                    @confirm="calendar.onConfirmCalendar"
                    :min-date="calendar.minDate" :max-date="calendar.maxDate" type="multiple"/>

      <van-field
          v-model="date.dateResult"
          :is-link="memo.isLink" readonly
          name="datePicker"
          label="选择日期"
          @click="date.showDatePicker = memo.permitClick"
          v-if="date.showDateSelect"
      />
      <van-popup v-model:show="date.showDatePicker" position="bottom">
        <van-date-picker @confirm="date.onConfirmDate" @cancel="date.showDatePicker = false"
                         :columns-type="date.dateColumnsType"
                         :formatter="date.dateFormatter"
        />
      </van-popup>

      <van-field
          v-model="week.weekResult"
          :is-link="memo.isLink" readonly
          name="week"
          label="选择星期"
          placeholder="点击选择星期"
          @click="week.showWeek = memo.permitClick"
          v-if="week.showWeekSelect"
      />
      <van-popup v-model:show="week.showWeek" position="bottom">
        <van-picker
            :columns="week.weekColumns"
            @confirm="week.onConfirmWeek"
            @cancel="week.showWeek = false"
        />
      </van-popup>

      <!-- 时间选择 -->
      <div class="van-hairline--bottom">
        <div v-for="(timeItem, index) in time.timeItemList" :key="timeItem.id">
          <van-field v-model="timeItem.timeResult"
                     :is-link="index === time.timeItemList.length - 1 ? memo.isLink : false"
                     readonly
                     name="timePicker" :label="index === 0 ? '时间选择' : ' '"
                     placeholder="可选择多个提醒时间"
                     @click="timeItem.showTimePicker = memo.permitClick"
                     v-if="time.showTimeSelect"
                     :right-icon="memo.isLink && index < time.timeItemList.length - 1 ? 'cross' : ''"
                     @click-right-icon="time.removeTimeItem(index)"
          />
          <van-popup v-model:show="timeItem.showTimePicker" position="bottom" @open="time.onTimePickerOpen(index)">
            <van-time-picker v-model="time.selectedTime"
                             @confirm="(selectedObj) => time.onConfirmTime(selectedObj, index)"
                             @cancel="time.showTimePicker = false"
                             :columns-type="time.timeColumnsType"/>
          </van-popup>
        </div>
      </div>

      <!-- 提醒次数 -->
      <van-field name="remindFreqStepper" label="提醒次数" v-if="remindFreq.showRemindFreq">
        <template #input>
          <van-stepper v-model="remindFreq.remindFreqValue"
                       max="3"
                       :disable-plus="memo.readOnly" :disable-minus="memo.readOnly" :disable-input="memo.readOnly"/>
        </template>
      </van-field>

      <!-- 再提醒间隔 -->
      <van-field v-model="remindInterval.remindIntervalText" name="remindInterval"
                 :is-link="memo.isLink" readonly
                 label="再提醒间隔"
                 @click="remindInterval.remindIntervalShowPicker = memo.permitClick"
                 v-if="remindInterval.showRemindInterval"
      />
      <van-popup v-model:show="remindInterval.remindIntervalShowPicker" round position="bottom">
        <van-picker :columns="remindInterval.remindIntervalColumns"
                    @cancel="remindInterval.remindIntervalShowPicker = false"
                    @confirm="remindInterval.onConfirmRemindInterval" v-model="remindInterval.selectInterval"/>
      </van-popup>
      <!--自动完成-->
<!--      <van-field name="remindFreqStepper" label="自动完成" v-if="remindFreq.showRemindFreq">-->
      <van-field label="自动完成" v-if="autoComplete.showAutoComplete">
        <template #input>
          <van-switch v-model="autoComplete.autoCompleteFlag" :disabled ="memo.readOnly"/>
        </template>
      </van-field>

      <!--备忘内容-->
      <van-field name="content" label="备忘内容" rows="3" type="textarea" autosize
                 :readonly="memo.readOnly"
                 v-model="content"></van-field>

      <div style="margin: 16px;">
        <van-row gutter="20" justify="center">
          <van-col span="8" v-if="memo.showAddMemoBtn">
            <van-button round block type="primary" @click="memo.addMemo">
              新增
            </van-button>
          </van-col>
          <van-col span="8" v-if="memo.showEditMemoBtn">
            <van-button round block type="primary" @click="memo.editMemo">
              编辑
            </van-button>
          </van-col>
        </van-row>
        <van-row gutter="20" justify="center">
          <van-col span="8" v-if="memo.showRemoveMemoBtn">
            <van-button round block type="danger" @click="memo.removeMemo">
              删除
            </van-button>
          </van-col>
          <van-col span="8" v-if="memo.showSubmitBtn">
            <van-button round block type="primary" native-type="submit"
                        :disabled="!isSubmitEnabled">
              {{ memo.submitBtnLabel }}
            </van-button>
          </van-col>
        </van-row>
      </div>

    </van-form>
    <!--  提交弹窗  -->
    <van-dialog v-model:show="dialog.showConfirmDialog"
                message="备忘成功！" confirmButtonText="继续备忘" cancelButtonText="查看备忘" show-cancel-button
                @confirm="dialog.continueCreateMemo" @cancel="dialog.viewMemo"
    >
    </van-dialog>
  </div>
</template>

<script setup>
import {computed, ref, reactive} from 'vue';
import dayjs from 'dayjs';
import {useRoute, useRouter} from 'vue-router';
import {showDialog, showConfirmDialog, showNotify} from 'vant';
import {useStore} from "vuex";
import {addWeeks, addYears} from "date-fns";
import {isStringNotEmpty, isStringEmpty, isArrEmpty} from '@/service/utils'
import {convertToDateArr, convertToDate} from '@/service/dateUtils'
import memoApi from '@/api/memo'
import userApi from "@/api/user";

// 在组件挂载后获取路由参数
const router = useRouter();
const route = useRoute();


// 时间类型
const timeType = reactive({
  TimeTypeValueEnum: {
    EXACT_TIME: {text: '指定时间', value: 'exactTime'},
    NO_TIME: {text: '不指定时间', value: 'noTime'},
    EVERY_DAY: {text: '每天', value: 'everyday'},
    EVERY_WEEK: {text: '每周', value: 'everyWeek'},
    EVERY_MONTH: {text: '每月', value: 'everyMonth'},
    EVERY_YEAR: {text: '每年', value: 'everyYear'},
  },
  timeTypeText: ref(),
  timeTypeValue: ref(),
  timeTypeColumns: ref(),
  timeTypeShowPicker: false,
  selectedTimeType: ref(),
  initOtherSelect: (selectText, selectValue) => {
    timeType.timeTypeShowPicker = false;
    timeType.timeTypeText = selectText;
    timeType.timeTypeValue = selectValue;

    // 恢复默认值
    {
      week.init();
      date.init();
      calendar.init();
      time.init();
      remindInterval.init();
      remindFreq.init();
    }

    week.showWeekSelect = false;
    calendar.showCalendarSelect = false;
    date.showDateSelect = false;
    time.showTimeSelect = false;
    remindInterval.showRemindInterval = false;
    remindFreq.showRemindFreq = false;
    autoComplete.showAutoComplete = true;

    console.log("selectValue", selectValue);

    if (selectValue === timeType.TimeTypeValueEnum.EVERY_YEAR.value) {
      // date.changeDateColumnsType(['year', 'month', 'day']);
      date.showDateSelect = true;
      time.showTimeSelect = true;
      remindInterval.showRemindInterval = true;
      remindFreq.showRemindFreq = true;
      date.changeDateColumnsType(['month', 'day']);
    } else if (selectValue === timeType.TimeTypeValueEnum.EVERY_MONTH.value) {
      date.showDateSelect = true;
      time.showTimeSelect = true;
      remindInterval.showRemindInterval = true;
      remindFreq.showRemindFreq = true;
      date.changeDateColumnsType(['day']);
    } else if (selectValue === timeType.TimeTypeValueEnum.EVERY_WEEK.value) {
      week.showWeekSelect = true;
      time.showTimeSelect = true;
      remindInterval.showRemindInterval = true;
      remindFreq.showRemindFreq = true;
    } else if (selectValue === timeType.TimeTypeValueEnum.EVERY_DAY.value) {
      time.showTimeSelect = true;
      remindInterval.showRemindInterval = true;
      remindFreq.showRemindFreq = true;
    } else if (selectValue === timeType.TimeTypeValueEnum.NO_TIME.value) {
      autoComplete.showAutoComplete = false;
    } else if (selectValue === timeType.TimeTypeValueEnum.EXACT_TIME.value) {
      calendar.showCalendarSelect = true;
      time.showTimeSelect = true;
      remindInterval.showRemindInterval = true;
      remindFreq.showRemindFreq = true;

    }
  },
  onConfirmTimeType: ({selectedOptions}) => {
    const selectText = selectedOptions[0].text;
    const selectValue = selectedOptions[0].value;
    timeType.initOtherSelect(selectText, selectValue);
  },
  init: (timeTypeValue) => {
    timeType.timeTypeColumns = Object.values(timeType.TimeTypeValueEnum);

    if (timeTypeValue !== undefined) {
      for (const key in timeType.TimeTypeValueEnum) {
        const item = timeType.TimeTypeValueEnum[key];
        if (timeTypeValue === item.value) {
          timeType.timeTypeText = item.text;
          timeType.timeTypeValue = item.value;
          timeType.selectedTimeType = [item.value];
          break;
        }
      }
    } else {
      timeType.timeTypeText = timeType.TimeTypeValueEnum.EXACT_TIME.text;
      timeType.timeTypeValue = timeType.TimeTypeValueEnum.EXACT_TIME.value;
    }

    timeType.initOtherSelect(timeType.timeTypeText, timeType.timeTypeValue);
  }
})

// 备忘类型
const memoType = reactive({
  MemoTypeValueEnum: {
    PERSONAL: {text: '个人备忘', value: 'personal'},
    GROUP: {text: '用户组备忘', value: 'group'},
  },
  memoTypeText: ref(),
  memoTypeValue: ref(),
  memoTypeColumns: ref(),
  memoTypeShowPicker: false,
  selectedMemoType: ref(),
  changeMemoType: (selectText, selectValue) => {
    memoType.memoTypeShowPicker = false;
    memoType.memoTypeText = selectText;
    memoType.memoTypeValue = selectValue;
    memoType.selectedMemoType = [memoType.memoTypeValue];
  },
  onConfirmMemoType: ({selectedOptions}) => {
    const selectText = selectedOptions[0].text;
    const selectValue = selectedOptions[0].value;
    memoType.changeMemoType(selectText, selectValue);
  },
  init: (memoTypeValue) => {
    memoType.memoTypeColumns = Object.values(memoType.MemoTypeValueEnum);

    if (memoTypeValue !== undefined) {
      for (const key in memoType.MemoTypeValueEnum) {
        const item = memoType.MemoTypeValueEnum[key];
        if (memoTypeValue === item.value) {
          const selectText = item.text;
          const selectValue = item.value;
          memoType.changeMemoType(selectText, selectValue);
          break;
        }
      }
    } else {
      const selectText = memoType.MemoTypeValueEnum.PERSONAL.text;
      const selectValue = memoType.MemoTypeValueEnum.PERSONAL.value;
      memoType.changeMemoType(selectText, selectValue);
    }
  }
})


const currentDate = dayjs();
// 日历选择
const calendar = reactive({
  showCalendarSelect: true,
  calendarResultList: [],
  selectDateStrList: ref(), // 选择日历上默认选上的日期
  showCalendar: ref(false),
  minDate: ref(),
  maxDate: ref(),
  getCalendarResult: () => {
    let dateStrList = [];
    for (let i = 0; i < calendar.calendarResultList.length; i++) {
      let dateStr = calendar.calendarResultList[i].dateStr;
      dateStrList.push(dateStr);
    }
    let calendarResult = dateStrList.join("|");
    return calendarResult;
  },
  onConfirmCalendar: (selectedValues) => {
    console.log("onConfirmCalendar", selectedValues);

    let dateStrList = []
    let dateList = []
    for (let i = 0; i < selectedValues.length; i++) {
      dateStrList.push(dayjs(selectedValues[i]).format('YYYY-MM-DD'));
      dateList.push(dayjs(selectedValues[i]).toDate());
    }
    calendar.selectDateStrList = dateList;
    calendar.updateCalendarResultList(dateStrList);
    calendar.showCalendar = false;
  },
  updateCalendarResultList: (dateList) => {
    calendar.calendarResultList = [];
    if (isArrEmpty(dateList)) {
      calendar.calendarResultList.push({"id": 0, "dateStr": null});
    } else {
      for (let i = 0; i < dateList.length; i++) {
        calendar.calendarResultList.push({"id": i, "dateStr": dateList[i]});
      }
    }
  },
  init: (calendarResult) => {
    console.log("calendarResult", calendarResult);
    if (calendarResult === undefined) {
      // calendarResult = currentDate.format('YYYY-MM-DD');
      calendar.updateCalendarResultList(null);
    } else {
      let dateArr = convertToDateArr(calendarResult);
      console.log("dateArr:", dateArr);
      calendar.selectDateStrList = dateArr;
      let dateList = calendarResult.split("|");
      calendar.updateCalendarResultList(dateList);
      console.log("calendar.calendarResultList", calendar.calendarResultList);
      calendar.selectDateStrList = dateArr;
    }

    const today = new Date();
    calendar.minDate = new Date();
    calendar.maxDate = addYears(today, 5);
  }
});

// 日期选择
const date = reactive({
  showDateSelect: false,
  dateResult: ref(),
  showDatePicker: false,
  dateColumnsType: ref(),
  dateFormatter: (type, option) => {
    if (type === 'year') {
      option.text += '年';
    }
    if (type === 'month') {
      option.text += '月';
    }
    if (type === 'day') {
      option.text += '日';
    }
    return option;
  },
  onConfirmDate: ({selectedValues}) => {
    // 处理组件自带的bug
    if (date.dateColumnsType.length > 1) {
      date.dateResult = selectedValues.join("-");
    } else {
      date.dateResult = selectedValues[0];
    }
    date.showDatePicker = false;
  },
  changeDateColumnsType: (dateColumnsType) => {
    date.dateColumnsType = dateColumnsType;
  },
  init: (dateResult) => {
    date.dateResult = null;
    if (dateResult !== undefined) {
      date.dateResult = dateResult;
    }
    date.showDateSelect = false;
  }
});

// 星期选择
const week = reactive({
  showWeekSelect: false,
  showWeek: false,
  weekColumns: [
    {text: '星期一', value: '1'},
    {text: '星期二', value: '2'},
    {text: '星期三', value: '3'},
    {text: '星期四', value: '4'},
    {text: '星期五', value: '5'},
    {text: '星期六', value: '6'},
    {text: '星期日', value: '7'},
  ],
  weekResult: ref(),
  weekValue: ref(),
  onConfirmWeek: ({selectedOptions}) => {
    week.weekResult = selectedOptions[0].text;
    week.weekValue = selectedOptions[0].value;
    week.showWeek = false;
  },
  init: (weekValue) => {
    console.log("weekValue", weekValue);
    if (weekValue !== undefined) {
      for (const key in week.weekColumns) {
        const item = week.weekColumns[key];
        if (weekValue === item.value) {
          week.weekResult = item.text;
          week.weekValue = item.value;
          break;
        }
      }
    } else {
      week.weekResult = week.weekColumns[0].text;
      week.weekValue = week.weekColumns[0].value;
    }
  }
})

// 时间选择
const currentHour = currentDate.format('HH');

const time = reactive({
  showTimeSelect: true,
  timeNum: 1,
  timeItemList: [],
  timeResult: ref(),
  selectedTime: ref(),
  timeColumnsType: ['hour', 'minute', 'second'],
  lastSelectTime: '12:00:00',
  addTimeItem: (timeResult) => {
    time.timeItemList.push(
        {id: time.timeNum++, timeResult: timeResult, showTimePicker: false}
    );
  },
  removeTimeItem: (index) => {
    let oldTimeItemList = time.timeItemList;
    time.timeItemList = [];
    for (let i = 0; i < oldTimeItemList.length; i++) {
      if (i === index) {
        continue;
      } else {
        time.timeItemList.push(oldTimeItemList[i]);
      }
    }
    time.updateTimeResult();
  },
  updateTimeResult: () => {
    let timeList = [];
    for (let i = 0; i < time.timeItemList.length; i++) {
      if (time.timeItemList[i].timeResult !== '' && time.timeItemList[i].timeResult !== null) {
        timeList.push(time.timeItemList[i].timeResult);
      }
    }
    time.timeResult = timeList.join("|");
  },
  onConfirmTime: ({selectedValues}, index) => {
    time.timeItemList[index].timeResult = ref(selectedValues.join(':'));
    time.lastSelectTime = time.timeItemList[index].timeResult;
    time.timeItemList[index].showTimePicker = false;
    if (index === time.timeItemList.length - 1) {
      time.addTimeItem(null);
    }
    time.updateTimeResult();
  },
  onTimePickerOpen: (index) => {
    let timeItemResult = time.timeItemList[index].timeResult;
    if (timeItemResult === null || timeItemResult === '') {
      timeItemResult = time.lastSelectTime;
    }
    time.selectedTime = timeItemResult.split(':');
  },
  init: (timeResult) => {
    console.log("timeResult:", timeResult);
    if (timeResult !== undefined) {
      let timeArr = timeResult.split("|");
      time.timeItemList = [];
      for (let i = 0; i < timeArr.length; i++) {
        time.addTimeItem(timeArr[i]);
      }
    } else {
      // timeResult = currentHour + ':00:00';
      time.timeResult = null;
      let length = time.timeItemList.length;
      if (time.timeItemList.length > 0) {
        let lastTimeItem = time.timeItemList[length - 1];
        console.log("lastTimeItem:", lastTimeItem)
        if (isStringNotEmpty(lastTimeItem.timeResult)) {
          time.addTimeItem(null);
        }
      } else {
        time.addTimeItem(null);
      }
    }
    time.updateTimeResult();
  }
});

// 再提醒间隔
const remindInterval = reactive({
  showRemindInterval: true,
  remindIntervalColumns: [
    {text: '5分钟', value: 5},
    {text: '10分钟', value: 10},
    {text: '20分钟', value: 20},
  ],
  remindIntervalValue: null,
  remindIntervalText: null,
  selectInterval: ref(),
  remindIntervalShowPicker: false,
  changeRemindInterval: (selectText, selectValue) => {
    remindInterval.remindIntervalShowPicker = false;
    remindInterval.remindIntervalText = selectText;
    remindInterval.remindIntervalValue = selectValue;
    remindInterval.selectInterval = [remindInterval.remindIntervalValue];
  },
  onConfirmRemindInterval: ({selectedOptions}) => {
    const selectText = selectedOptions[0].text;
    const selectValue = selectedOptions[0].value;
    remindInterval.changeRemindInterval(selectText, selectValue);
  },
  init: (remindIntervalValue) => {
    if (remindIntervalValue !== undefined) {
      let matchFlag = false;
      for (const key in remindInterval.remindIntervalColumns) {
        const item = remindInterval.remindIntervalColumns[key];
        if (remindIntervalValue === item.value) {
          const selectText = item.text;
          const selectValue = item.value;
          remindInterval.changeRemindInterval(selectText, selectValue);
          matchFlag = true;
          break;
        }
      }
      if (!matchFlag) {
        const selectText = remindIntervalValue + "分钟";
        const selectValue = remindIntervalValue;
        remindInterval.changeRemindInterval(selectText, selectValue);
      }
    } else {
      const selectValue = remindInterval.remindIntervalColumns[1].value;
      const selectText = remindInterval.remindIntervalColumns[1].text;
      remindInterval.changeRemindInterval(selectText, selectValue);
    }

  }
});

// 再提醒次数
const remindFreq = reactive({
  showRemindFreq: true,
  remindFreqValue: 1,
  init: (remindFreqValue) => {
    if (remindFreqValue !== undefined) {
      remindFreq.remindFreqValue = remindFreqValue;
    } else {
      remindFreq.remindFreqValue = 1;
    }
  }
});

// 备忘内容
const content = ref('');

// 自动完成开关
const autoComplete = reactive({
  showAutoComplete: true,
  autoCompleteFlag: false,
  init: (autoCompleteFlag) => {
    autoComplete.showAutoComplete = true;
    autoComplete.autoCompleteFlag = autoCompleteFlag;
  }
});


const store = useStore();
const currentCode = computed(() => store.getters.getLoginCode);
const groupId = computed(() => store.getters.getGroupId);

const code = ref(route.query.code);

const dialog = reactive({
  showConfirmDialog: false,
  memoId: ref(),
  continueCreateMemo: () => {
    console.log("继续备忘")
  },
  viewMemo: () => {
    console.log("查看备忘")
    let selectedDate = currentDate.format('YYYY-MM-DD')
    memoApi.getMemoInfo(dialog.memoId)
        .then(function (response) {
          // 处理成功情况
          const result = response.data.result;
          console.log(result.latestRemindTimeStr === null)
          if (result !== undefined) {
            if (result.latestRemindTimeStr !== undefined && result.latestRemindTimeStr !== null) {
              selectedDate = dayjs(result.latestRemindTimeStr).format("YYYY-MM-DD");
            }
          }
        })
        .catch(function (error) {
          // 处理错误情况
          console.log(error);
        })
        .finally(() => {
          router.push({path: '/memo_record', query: {code: code.value, selectedDate: selectedDate}});
        })
  }
});


// 全局控制
const memo = reactive({
  memoId: ref(),
  isLink: false,
  readOnly: true,
  permitClick: false,
  showAddMemoBtn: true,
  showEditMemoBtn: true,
  showSubmitBtn: false,
  showRemoveMemoBtn: false,
  submitBtnLabel: "保存",
  switchToReadMode: () => {
    memo.isLink = false;
    memo.readOnly = true;
    memo.permitClick = false;
    memo.showAddMemoBtn = true;
    memo.showEditMemoBtn = true;
    memo.showSubmitBtn = false;
    memo.showRemoveMemoBtn = false;
  },
  switchToWriteMode: () => {
    memo.isLink = true;
    memo.readOnly = false;
    memo.permitClick = true;
    memo.showAddMemoBtn = false;
    memo.showEditMemoBtn = false;
    memo.showSubmitBtn = true;

    if (memo.memoId === undefined || memo.memoId === '') {
      memo.submitBtnLabel = "保存";
      memo.showRemoveMemoBtn = false;
    } else {
      memo.submitBtnLabel = "保存修改";
      memo.showRemoveMemoBtn = true;
    }

    // let length = time.timeItemList.length;
    // if (time.timeItemList.length > 0) {
    //   let lastTimeItem = time.timeItemList[length - 1];
    //   if (lastTimeItem.timeResult !== null) {
    //     time.addTimeItem(null);
    //   }
    // }
    time.init();

  },
  addMemo: () => {
    console.log("addMemo")
    memo.memoId = '';
    memo.switchToWriteMode();
  },
  editMemo: () => {
    console.log("editMemo")
    memo.switchToWriteMode();
  },
  removeMemo: () => {
    showConfirmDialog({
      message: "是否删除",
    }).then(() => {
      memoApi.removeMemo(currentCode.value, memo.memoId)
          .then(function (response) {
            if (response.data.code === 200) {
              console.log("备忘删除成功,memoId=", memo.memoId)
              showNotify({type: 'success', message: '删除成功'});
              location.reload(); // 刷新页面
            }
          })
          .catch(function (error) {
            console.log(error);
          });
    }).catch(() => {
      console.log("取消删除")
    });

  },
  addMemoInit: () => {
    memo.memoId = '';
    memo.switchToWriteMode();
    timeType.init();
    remindInterval.init();
    remindFreq.init();
    date.init();
    calendar.init();
    time.init();
    week.init();
    memoType.init();
  },
  init: () => {
    // 判断是查看备忘，还是编辑备忘
    memo.memoId = route.query.memoId;
    if (memo.memoId === undefined || memo.memoId === '') {
      memo.addMemoInit();
    } else {
      memoApi.getMemoInfo(memo.memoId)
          .then(function (response) {
            const code = response.data.code;
            if (code !== 200) {
              memo.addMemoInit();
              return;
            }
            // 处理成功情况
            const result = response.data.result;
            if (result !== undefined) {
              const arr = result.remindTime.split(" ");
              const timeTypeValue = arr[0];
              timeType.init(timeTypeValue);

              if (timeTypeValue === timeType.TimeTypeValueEnum.EVERY_YEAR.value) {
                const dateResult = arr[1];
                date.init(dateResult);
                const timeResult = arr[2];
                time.init(timeResult);
              } else if (timeTypeValue === timeType.TimeTypeValueEnum.EVERY_MONTH.value) {
                const dateResult = arr[1];
                date.init(dateResult);
                const timeResult = arr[2];
                time.init(timeResult);
              } else if (timeTypeValue === timeType.TimeTypeValueEnum.EVERY_WEEK.value) {
                const weekValue = arr[1];
                week.init(weekValue);
                const timeResult = arr[2];
                time.init(timeResult);
              } else if (timeTypeValue === timeType.TimeTypeValueEnum.EVERY_DAY.value) {
                const timeResult = arr[1];
                time.init(timeResult);
              } else if (timeTypeValue === timeType.TimeTypeValueEnum.NO_TIME.value) {
              } else if (timeTypeValue === timeType.TimeTypeValueEnum.EXACT_TIME.value) {
                const calendarResult = arr[1];
                calendar.init(calendarResult);
                const timeResult = arr[2];
                time.init(timeResult);
              }
            }

            const remindFreqValue = result.remindFreq;
            const remindIntervalValue = result.remindInterval;
            const contentValue = result.content;
            const memoTypeValue = result.memoType;
            const autoCompleteFlag = result.autoCompleteFlag;

            remindInterval.init(remindIntervalValue);
            content.value = contentValue;
            remindFreq.init(remindFreqValue);
            memoType.init(memoTypeValue);
            autoComplete.init(autoCompleteFlag)

          })
          .catch(function (error) {
            // 处理错误情况
            console.log(error);
            memo.addMemoInit();
          });
    }
  }
});
memo.init();

const onSubmit = (values) => {
  console.log('submit', values);
  let remindTime = timeType.timeTypeValue;

  if (timeType.timeTypeValue === timeType.TimeTypeValueEnum.EXACT_TIME.value) {

    let calendarResult = calendar.getCalendarResult();
    remindTime += " " + calendarResult + " " + time.timeResult;
  } else if (timeType.timeTypeValue === timeType.TimeTypeValueEnum.NO_TIME.value) {
    // 暂无逻辑
    remindFreq.remindFreqValue = null;
    remindInterval.remindIntervalValue = null;
  } else if (timeType.timeTypeValue === timeType.TimeTypeValueEnum.EVERY_DAY.value) {
    remindTime += " " + time.timeResult;
  } else if (timeType.timeTypeValue === timeType.TimeTypeValueEnum.EVERY_WEEK.value) {
    remindTime += " " + week.weekValue + " " + time.timeResult;
  } else if (timeType.timeTypeValue === timeType.TimeTypeValueEnum.EVERY_MONTH.value ||
      timeType.timeTypeValue === timeType.TimeTypeValueEnum.EVERY_YEAR.value
  ) {
    remindTime += " " + date.dateResult + " " + time.timeResult;
  }
  const data = {
    remindTime: remindTime,
    remindFreq: remindFreq.remindFreqValue,
    remindInterval: remindInterval.remindIntervalValue,
    content: content.value,
    autoCompleteFlag: autoComplete.autoCompleteFlag
  };
  console.log("autoCompleteFlag", autoComplete.autoCompleteFlag)

  if (memoType.MemoTypeValueEnum.GROUP.value === memoType.memoTypeValue) {
    data.groupId = groupId.value;
  }

  if (memo.memoId !== undefined && memo.memoId !== '') {
    data.memoId = memo.memoId;
  }

  memoApi.addMemo(currentCode.value, data)
      .then(function (response) {
        if (response.data.code === 200) {
          dialog.memoId = response.data.result.id;
          dialog.showConfirmDialog = true;

          // 获取用户邮箱，如果没绑定，则跳转绑定
          userApi.getUserInfo(currentCode.value)
              .then(function (response) {
                const info = response.data.result;
                const email = info.email;
                if (email === undefined) {
                  dialog.showConfirmDialog = false;
                  showConfirmDialog({
                    message: "备忘将通过邮箱提醒，请先绑定邮箱",
                  }).then(() => {
                    router.push({path: '/user_info', query: {code: code.value}});
                  });
                }
              })
              .catch(function (error) {
                // 处理错误情况
                console.log(error);
              })

        } else {
          let msg = response.data.msg;
          showNotify({type: 'warning', message: msg});
        }
      })
      .catch(function (error) {
        console.log(error);
      });
};

const isSubmitEnabled = computed(() => {
  let flag = true
  if (content.value === '') {
    flag = false;
  }
  if (timeType.timeTypeValue !== timeType.TimeTypeValueEnum.NO_TIME.value
      && time.timeResult === '') {
    flag = false;
  }
  return flag;
});


</script>

<style>
.memo .van-hairline--bottom:after {
  left: -41%;
  right: -41%;
}
</style>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* 半透明黑色遮罩层 */
  z-index: 999; /* 确保在最上层 */
}

h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}
</style>
